import { IImage } from "../../types";
import { ImageActionsTypes } from "../actions/imageActions";

export const SET_IS_IMAGES_LOADING_IN_PROGRESS =
  "image/SET_IS_IMAGES_LOADING_IN_PROGRESS";
export const SET_IMAGES = "image/SET_IMAGES";
export const SET_TOTAL_IMAGES = "image/SET_TOTAL_IMAGES";
export const SET_IS_IMAGES_DELETING_IN_PROGRESS =
  "image/SET_IS_IMAGES_DELETING_IN_PROGRESS";

const initialState = {
  isImagesLoadingInProgress: false,
  isImageDeletingInProgress: false,
  images: [] as IImage[],
  imagesTotal: 0,
};

type InitialStateType = typeof initialState;

export const imageReducer = (
  state = initialState,
  action: ImageActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_IS_IMAGES_LOADING_IN_PROGRESS:
    case SET_IMAGES:
    case SET_TOTAL_IMAGES:
    case SET_IS_IMAGES_DELETING_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
