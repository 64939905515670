export const mainUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3755/"
    : "https://sitegen-back.localigniter.com/";

export const siteStatuses = [
  { name: "initial", order: 1 },
  { name: "with_domain", order: 2 },
  { name: "with_content", order: 3 },
  { name: "with_html", order: 4 },
];

export const defaultFonts = [
  {
    value: "Arial, Helvetica, sans-serif",
    label: "Arial, Helvetica, sans-serif",
  },
  {
    value: "Verdana, Geneva, sans-serif",
    label: "Verdana, Geneva, sans-serif",
  },
  { value: "Tahoma, Geneva, sans-serif", label: "Tahoma, Geneva, sans-serif" },
  {
    value: '"Trebuchet MS", Helvetica, sans-serif',
    label: '"Trebuchet MS", Helvetica, sans-serif',
  },
  {
    value: '"Times New Roman", Times, serif',
    label: '"Times New Roman", Times, serif',
  },
  {
    value: 'Georgia, "Times New Roman", Times, serif',
    label: 'Georgia, "Times New Roman", Times, serif',
  },
  {
    value: '"Courier New", Courier, monospace',
    label: '"Courier New", Courier, monospace',
  },
  {
    value: '"Lucida Console", Monaco, monospace',
    label: '"Lucida Console", Monaco, monospace',
  },
  {
    value: '"Segoe UI", Tahoma, Geneva, sans-serif',
    label: '"Segoe UI", Tahoma, Geneva, sans-serif',
  },
  {
    value: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
    label: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
  },
];
