import {
  Breadcrumb,
  Button,
  Form,
  FormProps,
  Input,
  Popconfirm,
  Select,
  Switch,
  Tag,
  Typography,
} from "antd";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import React, { Dispatch, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SiteThunkType,
  deleteField,
  getOneSite,
  getSpecificPageValues,
  setInProgressSiteId,
  updateSite,
} from "../store/actions/siteActions";
import { AnyAction } from "redux";
import { AppStateType } from "../store";
import { IOnInProgressId, IOnSitesUpdate } from "../types";
import { getTags } from "../store/actions/tagActions";
import { defaultFonts, mainUrl } from "../helpers/constants";
import { StatusBar } from "../components/sites/StatusBar";
import { getTemplateTypes } from "../store/actions/templateActions";
import { SpecificPageValues } from "../components/sites/SpecificPageValues";
import { ReloadOutlined } from "@ant-design/icons";
import { TemplatesList } from "../components/sites/TemplatesList";

export const Site = () => {
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;
  const { Link, Title } = Typography;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  let { id } = useParams();
  const [valuesToUpdate, setValuesToUpdate] = useState<{
    [param: string]: string;
  }>({});
  const site = useSelector((state: AppStateType) => state.site.site);
  const socket = useSelector((state: AppStateType) => state.auth.socket);
  const isGettingSitesInProgress = useSelector(
    (state: AppStateType) => state.site.isGettingSitesInProgress
  );
  const isUpdatingSiteInProgress = useSelector(
    (state: AppStateType) => state.site.isUpdatingSiteInProgress
  );

  const inProgressId = useSelector(
    (state: AppStateType) => state.site.inProgressId
  );
  const tags = useSelector((state: AppStateType) => state.tag.tags);

  const templateTypes = useSelector(
    (state: AppStateType) => state.template.templateTypes
  );

  type FieldType = {
    [param: string]: string;
  };

  useEffect(() => {
    if (id) {
      dispatch(getOneSite(id));
      dispatch(getTemplateTypes());
      dispatch(getTags(1));
    }
  }, [id]);

  useEffect(() => {
    console.log("valuesToUpdate", valuesToUpdate);
  }, [valuesToUpdate]);

  useEffect(() => {
    if (site) {
      form.setFieldValue("name", site.name);
      form.setFieldValue("status", site.status);
      form2.setFieldValue("headerFont", site.headerFont);
      form2.setFieldValue("paragraphFont", site.paragraphFont);
      form2.setFieldValue("h1Size", site.h1Size);
      form2.setFieldValue("h1SizeMobile", site.h1SizeMobile);
      form2.setFieldValue("h2Size", site.h2Size);
      form2.setFieldValue("h2SizeMobile", site.h2SizeMobile);
      form2.setFieldValue("h3Size", site.h3Size);
      form2.setFieldValue("h3SizeMobile", site.h3SizeMobile);
      form2.setFieldValue("h4Size", site.h4Size);
      form2.setFieldValue("h4SizeMobile", site.h4SizeMobile);
      form2.setFieldValue("pSize", site.pSize);
      form2.setFieldValue("pSizeMobile", site.pSizeMobile);
      tags.forEach((t) => {
        if (t.fieldInDb && site?.[t.fieldInDb]) {
          form.setFieldValue(t.fieldInDb, site?.[t.fieldInDb]);
        }
      });
    }
  }, [site, tags]);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (values && id) {
      dispatch(updateSite(values, id));
    }
  };

  const onSitesUpdate = ({ siteId }: IOnSitesUpdate) => {
    console.log("siteId", siteId);
    if (siteId) {
      dispatch(getOneSite(siteId));
      dispatch(getSpecificPageValues(siteId));
    }
  };

  const onInProgressId = ({ inProgressId }: IOnInProgressId) => {
    console.log("inProgressId", inProgressId);
    dispatch(setInProgressSiteId(inProgressId));
  };

  useEffect(() => {
    socket?.on("sitesUpdate", onSitesUpdate);
    socket?.on("inProgressId", onInProgressId);
  }, [socket]);

  const { TextArea } = Input;

  const cronOnChange = (checked: boolean) => {
    if (id) {
      dispatch(updateSite({ statusAutoProgressOnCreate: `${checked}` }, id));
    }
  };

  const errorOnChange = (checked: boolean) => {
    if (id) {
      dispatch(updateSite({ error: `${checked}` }, id));
    }
  };

  // const handleChangeStyles = (value: string, option: any) => {
  //   console.log(`selected ${value}`);
  //   console.log(option);
  // };

  const advanageIconsIndexes = [1, 2, 3, 4] as 1[] | 2[] | 3[] | 4[];
  return (
    <div style={{ overflowX: "scroll" }}>
      <div style={{ padding: 20 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflowX: "scroll",
          }}
        >
          <Breadcrumb
            style={{ marginTop: 10, marginBottom: 20 }}
            items={[
              {
                href: "/sites",
                title: (
                  <>
                    <ArrowLeftOutlined />
                    <span>Sites</span>
                  </>
                ),
              },
              {
                title: "Site",
              },
            ]}
          />
          {isGettingSitesInProgress && <LoadingOutlined />}
        </div>

        <Title style={{ marginTop: 0 }} level={4}>
          Status flow
        </Title>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {id && site && <StatusBar id={id} site={site} />}
          <div>
            {site?.htmlBuildLocation && (
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                <Link
                  target="_blank"
                  href={`${mainUrl}${site?.htmlBuildLocation?.replace(
                    "/data/uploads/",
                    ""
                  )}`}
                >
                  <LinkOutlined /> HTML BUILD
                </Link>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Popconfirm
                    title="Delete"
                    description="Are you sure to delete?"
                    onConfirm={() =>
                      id ? dispatch(deleteField("htmlBuildLocation", id)) : null
                    }
                  >
                    <Button
                      style={{ marginTop: 10, color: "red" }}
                      loading={isUpdatingSiteInProgress}
                      type="link"
                      size="middle"
                    >
                      Delete build
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            )}
          </div>
          <Button
            style={{ marginTop: 20, marginLeft: 30 }}
            onClick={() => {
              if (id) {
                dispatch(
                  updateSite({ status: "initial", isInProgress: false }, id)
                );
              }
            }}
            type="primary"
            icon={<ReloadOutlined />}
            disabled={isUpdatingSiteInProgress || !!inProgressId}
            loading={!!inProgressId}
          >
            Build/Rebuild
          </Button>
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <div style={{ padding: 20, paddingTop: 0 }}>
          <Title style={{ marginBottom: 20, color: "red" }} level={3}>
            BASE INFO, INDEX PAGE
          </Title>
          <Form
            style={{ minWidth: 400 }}
            form={form}
            layout="vertical"
            //onFinish={onFinish}
          >
            <Form.Item<FieldType>
              label={
                <Title style={{ marginTop: 0 }} level={4}>
                  Site name
                </Title>
              }
              name="name"
              rules={[{ required: true, message: "Site Name can't be empty" }]}
            >
              <Input size="middle" placeholder="Enter site name" />
            </Form.Item>
            {[...tags].reverse().map((t) =>
              t.fieldInDb && site?.[t.fieldInDb] ? (
                <Form.Item<FieldType>
                  key={t.fieldInDb}
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      {t.fieldInDb}
                    </Title>
                  }
                  name={t.fieldInDb}
                >
                  <TextArea
                    rows={2}
                    size="middle"
                    placeholder={`Enter ${t.fieldInDb}`}
                    onChange={(val) =>
                      setValuesToUpdate({
                        ...valuesToUpdate,
                        [t.fieldInDb]: val.target.value,
                      })
                    }
                  />
                </Form.Item>
              ) : null
            )}

            {id && (
              <Button
                style={{ width: 150 }}
                htmlType="submit"
                loading={isUpdatingSiteInProgress}
                type="primary"
                size="middle"
                disabled={false}
                onClick={() => {
                  if (id) {
                    dispatch(updateSite(valuesToUpdate, id));
                  }

                  setValuesToUpdate({});
                }}
              >
                Save
              </Button>
            )}
          </Form>
        </div>

        {id && <SpecificPageValues siteId={id} />}

        <div style={{ borderLeft: "1px solid", paddingLeft: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 20,
              paddingBottom: 10,
            }}
          >
            <div>
              <Title style={{ marginTop: 0 }} level={4}>
                Autoprogress
              </Title>

              <Switch
                loading={isUpdatingSiteInProgress}
                checked={site?.statusAutoProgressOnCreate}
                onChange={cronOnChange}
              />
            </div>

            <div style={{ marginLeft: 50 }}>
              <Title style={{ marginTop: 0 }} level={4}>
                Error
              </Title>

              <Switch
                disabled={!site?.error}
                loading={isUpdatingSiteInProgress}
                defaultChecked={site?.error}
                checked={site?.error}
                onChange={errorOnChange}
              />
            </div>
          </div>

          {templateTypes.some((tt) => !!site?.[`${tt.type}Template`]) && (
            <div>
              <Title style={{ marginTop: 20 }} level={4}>
                Templates
              </Title>
              <TemplatesList
                templateTypes={templateTypes}
                site={site}
                siteId={id}
              />
            </div>
          )}

          {site?.companyLogo && (
            <div
              style={{
                paddingBottom: 10,
                flexDirection: "row",
                width: "100%",
                display: "flex",
              }}
            >
              <div>
                <Title style={{ marginTop: 20 }} level={4}>
                  Company logo
                </Title>

                <img
                  width={200}
                  alt="company logo"
                  src={`${mainUrl}${site?.companyLogo?.replace(
                    "/data/uploads/",
                    ""
                  )}`}
                />
              </div>
              <div
                style={{
                  paddingTop: 50,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Popconfirm
                  title="Delete"
                  description="Are you sure to delete?"
                  onConfirm={() =>
                    id ? dispatch(deleteField("companyLogo", id)) : null
                  }
                >
                  <Button
                    style={{ width: 150, color: "red" }}
                    loading={isUpdatingSiteInProgress}
                    type="link"
                    size="middle"
                  >
                    Delete logo
                  </Button>
                </Popconfirm>
              </div>
            </div>
          )}

          {advanageIconsIndexes.map((i) => {
            if (site?.[`advantageIcon${i}`]) {
              return (
                <div
                  key={i}
                  style={{
                    paddingBottom: 10,
                    flexDirection: "row",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div>
                    <Title style={{ marginTop: 20 }} level={4}>
                      Advantage icon {i}
                    </Title>

                    <img
                      width={200}
                      alt="company logo"
                      src={`${mainUrl}${site?.[`advantageIcon${i}`]?.replace(
                        "/data/uploads/",
                        ""
                      )}`}
                    />
                  </div>
                  <div
                    style={{
                      paddingTop: 50,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Popconfirm
                      title="Delete"
                      description="Are you sure to delete?"
                      onConfirm={() =>
                        id
                          ? dispatch(deleteField(`advantageIcon${i}`, id))
                          : null
                      }
                    >
                      <Button
                        style={{ width: 150, color: "red" }}
                        loading={isUpdatingSiteInProgress}
                        type="link"
                        size="middle"
                      >
                        Delete icon
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
        <div style={{ borderLeft: "1px solid", paddingLeft: 20 }}>
          <Title style={{ marginTop: 20 }} level={3}>
            Site global styles
          </Title>
          <Form
            style={{ minWidth: 400 }}
            form={form2}
            layout="vertical"
            onFinish={onFinish}
          >
            {site?.headerFont && (
              <Form.Item<FieldType>
                label={
                  <Title style={{ marginTop: 0 }} level={4}>
                    Headers font
                  </Title>
                }
                name="headerFont"
              >
                <Select style={{ width: "100%" }} options={defaultFonts} />
              </Form.Item>
            )}
            {site?.paragraphFont && (
              <Form.Item<FieldType>
                label={
                  <Title style={{ marginTop: 0 }} level={4}>
                    Paragraph font
                  </Title>
                }
                name="paragraphFont"
              >
                <Select style={{ width: "100%" }} options={defaultFonts} />
              </Form.Item>
            )}
            <div
              style={{ width: "100%", flexDirection: "row", display: "flex" }}
            >
              {site?.h1Size && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      H1 size
                    </Title>
                  }
                  name="h1Size"
                >
                  <Input style={{ width: 50 }} size="middle" />
                </Form.Item>
              )}
              {site?.h1SizeMobile && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0, marginLeft: 20 }} level={4}>
                      H1 size on mobile
                    </Title>
                  }
                  name="h1SizeMobile"
                >
                  <Input style={{ width: 50, marginLeft: 20 }} size="middle" />
                </Form.Item>
              )}
            </div>
            <div
              style={{ width: "100%", flexDirection: "row", display: "flex" }}
            >
              {site?.h2Size && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      H2 size
                    </Title>
                  }
                  name="h2Size"
                >
                  <Input style={{ width: 50 }} size="middle" />
                </Form.Item>
              )}
              {site?.h2SizeMobile && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0, marginLeft: 20 }} level={4}>
                      H2 size on mobile
                    </Title>
                  }
                  name="h2SizeMobile"
                >
                  <Input style={{ width: 50, marginLeft: 20 }} size="middle" />
                </Form.Item>
              )}
            </div>
            <div
              style={{ width: "100%", flexDirection: "row", display: "flex" }}
            >
              {site?.h3Size && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      H3 size
                    </Title>
                  }
                  name="h3Size"
                >
                  <Input style={{ width: 50 }} size="middle" />
                </Form.Item>
              )}
              {site?.h3SizeMobile && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0, marginLeft: 20 }} level={4}>
                      H3 size on mobile
                    </Title>
                  }
                  name="h3SizeMobile"
                >
                  <Input style={{ width: 50, marginLeft: 20 }} size="middle" />
                </Form.Item>
              )}
            </div>
            <div
              style={{ width: "100%", flexDirection: "row", display: "flex" }}
            >
              {site?.h4Size && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      H4 size
                    </Title>
                  }
                  name="h3Size"
                >
                  <Input style={{ width: 50 }} size="middle" />
                </Form.Item>
              )}
              {site?.h4SizeMobile && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0, marginLeft: 20 }} level={4}>
                      H4 size on mobile
                    </Title>
                  }
                  name="h4SizeMobile"
                >
                  <Input style={{ width: 50, marginLeft: 20 }} size="middle" />
                </Form.Item>
              )}
            </div>
            <div
              style={{ width: "100%", flexDirection: "row", display: "flex" }}
            >
              {site?.pSize && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      Paragraph size
                    </Title>
                  }
                  name="pSize"
                >
                  <Input style={{ width: 50 }} size="middle" />
                </Form.Item>
              )}
              {site?.pSizeMobile && (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0, marginLeft: 20 }} level={4}>
                      Paragraph size on mobile
                    </Title>
                  }
                  name="pSizeMobile"
                >
                  <Input style={{ width: 50, marginLeft: 20 }} size="middle" />
                </Form.Item>
              )}
            </div>

            <Button
              style={{ width: 150 }}
              htmlType="submit"
              loading={isUpdatingSiteInProgress}
              type="primary"
              size="middle"
              disabled={false}
            >
              Save
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
