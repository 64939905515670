import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_IS_IMAGES_LOADING_IN_PROGRESS,
  SET_IMAGES,
  SET_TOTAL_IMAGES,
  SET_IS_IMAGES_DELETING_IN_PROGRESS,
} from "../reducers/imageReducer";
import { imagesApi } from "../../api";
import { notification } from "antd";
import { IImage } from "../../types";

type SetIsImagesLoadingInProgress = {
  type: typeof SET_IS_IMAGES_LOADING_IN_PROGRESS;
  payload: { isImagesLoadingInProgress: boolean };
};

type SetImages = {
  type: typeof SET_IMAGES;
  payload: { images: IImage[] };
};

type SetTotalImages = {
  type: typeof SET_TOTAL_IMAGES;
  payload: { imagesTotal: number };
};

type SetIsImageDeletingInProgress = {
  type: typeof SET_IS_IMAGES_DELETING_IN_PROGRESS;
  payload: { isImageDeletingInProgress: boolean };
};

export type ImageActionsTypes =
  | SetIsImagesLoadingInProgress
  | SetIsImageDeletingInProgress
  | SetImages
  | SetTotalImages;

export const setIsImagesLoadingInProgress = (
  isImagesLoadingInProgress: boolean
): SetIsImagesLoadingInProgress => ({
  type: SET_IS_IMAGES_LOADING_IN_PROGRESS,
  payload: { isImagesLoadingInProgress },
});

export const setIsImageDeletingInProgress = (
  isImageDeletingInProgress: boolean
): SetIsImageDeletingInProgress => ({
  type: SET_IS_IMAGES_DELETING_IN_PROGRESS,
  payload: { isImageDeletingInProgress },
});

export const setImages = (images: IImage[]): SetImages => ({
  type: SET_IMAGES,
  payload: { images },
});

export const setTotalImages = (imagesTotal: number): SetTotalImages => ({
  type: SET_TOTAL_IMAGES,
  payload: { imagesTotal },
});

export type ImageThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  ImageActionsTypes
>;

export const getImages =
  (page: number, category: string): ImageThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsImagesLoadingInProgress(true));
      const response = await imagesApi.loadImages(page, category);
      if (response.status === "success") {
        console.log("response", response);
        dispatch(setTotalImages(response?.totalImages || 0));
        dispatch(setImages(response?.images || []));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of images",
      });
    } finally {
      dispatch(setIsImagesLoadingInProgress(false));
    }
  };

export const deleteImage =
  (imageId: string, page: number, category: string): ImageThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsImageDeletingInProgress(true));
      const response = await imagesApi.deleteImage(imageId);
      if (response.status === "success") {
        console.log("response", response);
        dispatch(getImages(page, category));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error image deleting",
      });
    } finally {
      dispatch(setIsImageDeletingInProgress(false));
    }
  };
