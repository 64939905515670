import {
  Input,
  Table,
  Typography,
  Pagination,
  Select,
  Form,
  Checkbox,
  CheckboxProps,
} from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { IFormattedSelectItem, ISiteRow, SortByField } from "../../types";
import { useSearchParams } from "react-router-dom";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { AppStateType } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  SiteThunkType,
  setPage,
  setSearchByCity,
  setSearchByName,
  setSearchByState,
  setSortBy,
  setSortByField,
} from "../../store/actions/siteActions";
import { AnyAction } from "redux";
import { getBeautifullDate } from "../../helpers/helpers";
import { FileTextOutlined } from "@ant-design/icons";
import { SearchCityInput } from "./SearchCityInput";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { LoadingOutlined } from "@ant-design/icons";

interface IProps {
  checkedSites: string[];
  setCheckedSites: (param: string[]) => void;
}

export const SitesList = ({ checkedSites, setCheckedSites }: IProps) => {
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;
  const [dataSource, setDataSource] = useState<ISiteRow[]>([]);

  const [columns, setColumns] = useState<any[]>([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentSearchParams, setCurrentSearchParams] = useState<{
    [param: string]: string;
  }>();
  const [formattedStates, setFormattedStates] = useState(
    [] as IFormattedSelectItem[]
  );
  const { Text, Link } = Typography;
  const clickSortByField = (param: SortByField) => {
    setSearchParams({
      ...currentSearchParams,
      sortByField: param,
      sortBy: "asc",
    });
  };
  const sortBy = useSelector((state: AppStateType) => state.site.sortBy);
  const sortByField = useSelector(
    (state: AppStateType) => state.site.sortByField
  );
  const sites = useSelector((state: AppStateType) => state.site.sites);
  const states = useSelector((state: AppStateType) => state.location.states);
  const sitesTotal = useSelector(
    (state: AppStateType) => state.site.sitesTotal
  );

  const inProgressId = useSelector(
    (state: AppStateType) => state.site.inProgressId
  );

  const isGettingStatesInProgress = useSelector(
    (state: AppStateType) => state.location.isGettingStatesInProgress
  );
  const getArrowIcon = () => {
    return sortBy === "asc" ? <ArrowUpOutlined /> : <ArrowDownOutlined />;
  };
  const setSort = () => {
    if (currentSearchParams?.sortBy === "asc") {
      setSearchParams({ ...currentSearchParams, sortBy: "desc" });
    } else {
      setSearchParams({ ...currentSearchParams, sortBy: "asc" });
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    const arr = [] as IFormattedSelectItem[];
    states.forEach((s) => {
      arr.push({ value: s._id, label: s.State });
    });
    setFormattedStates(arr);
  }, [states]);

  const getStatusType = (status: string) => {
    if (status === "with_domain") {
      return undefined;
    }
    if (status === "with_content") {
      return "warning";
    }
    if (status === "with_html") {
      return "success";
    }
    return "secondary";
  };

  const onChange = (
    e: CheckboxChangeEvent,
    siteId: string,
    checked: string[]
  ) => {
    if (!e.target.checked) {
      setCheckedSites([...checked.filter((cs) => cs !== siteId)]);
    } else {
      setCheckedSites([...checked, siteId]);
    }
  };

  useEffect(() => {
    console.log("checkedSites", checkedSites);
  }, [checkedSites]);

  useEffect(() => {
    const ds = [] as ISiteRow[];
    sites.forEach((s) => {
      ds.push({
        key: s._id,
        check: (
          <Checkbox
            checked={checkedSites.includes(s._id)}
            onChange={(e) => onChange(e, s._id, checkedSites)}
          ></Checkbox>
        ),
        name: (
          <Link href={`/sites/${s._id}`}>
            <FileTextOutlined /> {inProgressId === s._id && <LoadingOutlined />}{" "}
            {s.name}
          </Link>
        ),
        state: s.state?.State,
        city: s.city.city,
        zip: s?.zip,
        county: s.county.county_full,
        company: s.company.name,
        created: getBeautifullDate(s.createdAt),
        updated: getBeautifullDate(s.updatedAt),
        //status: s.status,
        status: (
          <Text style={{ fontWeight: "bold" }} type={getStatusType(s.status)}>
            {s.status}
          </Text>
        ),
      });
    });
    setDataSource(ds);
  }, [sites, checkedSites]);

  const handleChangeState = (value: string) => {
    console.log("value", value);
    if (value.length > 0) {
      setSearchParams({ ...currentSearchParams, state: value.toString() });
    } else {
      searchParams.delete("state");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    setColumns([
      {
        title: "",
        dataIndex: "check",
        key: "check",
      },
      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>Site name</span>
            <Form
              name="form_sitename"
              fields={[
                {
                  name: ["sitename"],
                  value: currentSearchParams?.serchByName || "",
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="sitename">
                <Input
                  //defaultValue={currentSearchParams?.serchByName || ""}
                  allowClear
                  style={{ width: 140 }}
                  onBlur={(e) => {
                    if (e.target.value) {
                      setSearchParams({
                        ...currentSearchParams,
                        serchByName: e.target.value,
                      });
                    } else {
                      searchParams.delete("serchByName");
                      setSearchParams(searchParams);
                    }
                  }}
                  size="small"
                  placeholder="Search by name"
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "name",
        key: "name",
      },
      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>State</span>
            <Form
              name="form_states"
              fields={[
                {
                  name: ["states"],
                  value: currentSearchParams?.state?.split(",") || [],
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="states">
                <Select
                  mode="tags"
                  size="small"
                  loading={isGettingStatesInProgress}
                  showSearch
                  style={{ minWidth: 120 }}
                  placeholder="Filter by state"
                  onChange={handleChangeState}
                  optionFilterProp="children"
                  options={formattedStates}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "state",
        key: "state",
      },
      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>City</span>
            <SearchCityInput currentSearchParams={currentSearchParams} />
          </div>
        ),
        dataIndex: "city",
        key: "city",
      },
      {
        title: "Zip",
        dataIndex: "zip",
        key: "zip",
      },
      {
        title: "Company",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "County",
        dataIndex: "county",
        key: "county",
      },
      {
        title: (
          <Link
            onClick={() => {
              currentSearchParams?.sortByField !== "createdAt"
                ? clickSortByField("createdAt")
                : setSort();
            }}
          >
            {sortByField === "createdAt" && getArrowIcon()} Created
          </Link>
        ),
        dataIndex: "created",
        key: "created",
      },
      {
        title: (
          <Link
            onClick={() => {
              currentSearchParams?.sortByField !== "updatedAt"
                ? clickSortByField("updatedAt")
                : setSort();
            }}
          >
            {sortByField === "updatedAt" && getArrowIcon()} Updated
          </Link>
        ),
        dataIndex: "updated",
        key: "updated",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
    ]);
  }, [currentSearchParams, isGettingStatesInProgress, formattedStates, states]);

  useEffect(() => {
    console.log("currentSearchParams", currentSearchParams);
    dispatch(
      setSortBy((currentSearchParams?.sortBy as "desc" | "asc") || "desc")
    );
    dispatch(
      setSortByField(
        (currentSearchParams?.sortByField as SortByField) || "createdAt"
      )
    );
    dispatch(
      setPage(currentSearchParams?.page ? Number(currentSearchParams?.page) : 1)
    );
    dispatch(setSearchByName(currentSearchParams?.serchByName || ""));

    dispatch(setSearchByState(currentSearchParams?.state || ""));
    dispatch(setSearchByCity(currentSearchParams?.city || ""));
  }, [currentSearchParams]);

  useEffect(() => {
    setCurrentSearchParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  const onPageChange = (current: number) => {
    setSearchParams({ ...currentSearchParams, page: `${current}` });
  };

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      <div style={{ paddingTop: 20 }}>
        {sitesTotal > 0 && (
          <Pagination
            showTotal={(total) => `Total ${total} items`}
            pageSize={50}
            defaultCurrent={1}
            total={sitesTotal}
            onChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};
