import React, { Dispatch, useEffect, useState } from "react";
import { ISite, ITemplateType } from "../../types";
import { Button, Input, Popconfirm, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../store";
import {
  deleteField,
  SiteThunkType,
  updateSite,
} from "../../store/actions/siteActions";
import { AnyAction } from "redux";

interface ITemplatesList {
  templateTypes: ITemplateType[];
  site: ISite | null;
  siteId: string | undefined;
}

interface ITemplateRow {
  key: string;
  id?: string | React.JSX.Element;
  type?: React.JSX.Element;
  palette?: React.JSX.Element;
  action?: React.JSX.Element | string;
}

export const TemplatesList = ({
  templateTypes,
  site,
  siteId,
}: ITemplatesList) => {
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;

  const isUpdatingSiteInProgress = useSelector(
    (state: AppStateType) => state.site.isUpdatingSiteInProgress
  );
  const [columns, setColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<ITemplateRow[]>([]);
  const [editedTemplate, setEditedTemplate] = useState("");
  const [templateId, setTemplateId] = useState("");

  useEffect(() => {
    setColumns([
      {
        title: "id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "palette",
        dataIndex: "palette",
        key: "palette",
      },
      {
        title: "action",
        dataIndex: "action",
        key: "action",
      },
    ]);
  }, []);

  useEffect(() => {
    console.log("editedTemplate", editedTemplate);
  }, [editedTemplate]);

  useEffect(() => {
    const ds = [] as ITemplateRow[];
    templateTypes.forEach((tt) => {
      ds.push({
        key: tt._id,
        id:
          editedTemplate && editedTemplate === `${tt.type}Template` ? (
            <>
              <Input
                onChange={(val) => setTemplateId(val.target.value)}
                size="middle"
                placeholder="Enter template id"
              />
              <Button
                onClick={() =>
                  siteId
                    ? dispatch(
                        updateSite(
                          { [`${tt.type}Template`]: templateId },
                          siteId
                        )
                      )
                    : null
                }
                style={{ color: "blue" }}
                disabled={isUpdatingSiteInProgress}
                type="link"
                size="middle"
              >
                Save
              </Button>
              <Button
                onClick={() => setEditedTemplate("")}
                style={{ color: "red" }}
                disabled={isUpdatingSiteInProgress}
                type="link"
                size="middle"
              >
                Cancel
              </Button>
            </>
          ) : (
            site?.[`${tt.type}Template`]?._id
          ),
        type: <Tag color="magenta">{site?.[`${tt.type}Template`]?.type}</Tag>,
        palette:
          site?.[`${tt.type}Template`]?.palette === "dark" ? (
            <Tag color="black">
              {site?.[`${tt.type}Template`]?.palette.toUpperCase()}
            </Tag>
          ) : (
            <Tag color="default">
              {site?.[`${tt.type}Template`]?.palette.toUpperCase()}
            </Tag>
          ),
        action:
          !editedTemplate || editedTemplate !== `${tt.type}Template` ? (
            <>
              <Button
                onClick={() => setEditedTemplate(`${tt.type}Template`)}
                style={{ color: "blue" }}
                disabled={isUpdatingSiteInProgress}
                type="link"
                size="middle"
              >
                Edit
              </Button>
              <Popconfirm
                title="Delete"
                description="Are you sure to delete?"
                onConfirm={() =>
                  siteId
                    ? dispatch(deleteField(`${tt.type}Template`, siteId))
                    : null
                }
              >
                {site?.[`${tt.type}Template`]?._id && (
                  <Button
                    style={{ color: "red" }}
                    disabled={isUpdatingSiteInProgress}
                    type="link"
                    size="middle"
                  >
                    Delete
                  </Button>
                )}
              </Popconfirm>
            </>
          ) : (
            ""
          ),
      });
    });
    setDataSource(ds);
  }, [site, siteId, templateTypes, editedTemplate, templateId]);

  return <Table dataSource={dataSource} columns={columns} pagination={false} />;
};
