import { ISite, ISpecificPageValue, SortByField } from "../../types";
import { SiteActionsTypes } from "../actions/siteActions";

export const SET_IS_GETTING_SITES_IN_PROGRESS =
  "site/SET_IS_GETTING_SITES_IN_PROGRESS";
export const SET_IS_CREATING_SITE_IN_PROGRESS =
  "site/SET_IS_CREATING_SITE_IN_PROGRESS";
export const SET_SITES = "site/SET_SITES";
export const SET_SORT_BY_FIELD = "site/SET_SORT_BY_FIELD";
export const SET_SORT_BY = "site/SET_SORT_BY";
export const SET_PAGE = "site/SET_PAGE";
export const SET_SITES_TOTAL = "site/SET_SITES_TOTAL";
export const SET_SEARCH_BY_NAME = "site/SET_SEARCH_BY_NAME";
export const SET_SEARCH_BY_STATE = "site/SET_SEARCH_BY_STATE";
export const SET_SEARCH_BY_CITY = "site/SET_SEARCH_BY_CITY";
export const SET_SITE = "site/SET_SITE";
export const SET_SPECIFIC_PAGE_VALUES = "site/SET_SPECIFIC_PAGE_VALUES";
export const SET_IS_DELETION_IN_PROGRESS = "site/SET_IS_DELETION_IN_PROGRESS";
export const SET_IS_UPDATING_SITE_IN_PROGRESS =
  "site/SET_IS_UPDATING_SITE_IN_PROGRESS";
export const SET_IS_LOADING_SPECIFIC_PAGE_VALUES_IN_PROGRESS =
  "site/SET_IS_LOADING_SPECIFIC_PAGE_VALUES_IN_PROGRESS";
export const SET_IS_UPDATING_SPECIFIC_PAGE_VALUES_IN_PROGRESS =
  "site/SET_IS_UPDATING_SPECIFIC_PAGE_VALUES_IN_PROGRESS";
export const SET_IN_PROGRESS_SITE_ID = "site/SET_IN_PROGRESS_SITE_ID";

const initialState = {
  isGettingSitesInProgress: false,
  isCreatingSiteInProgress: false,
  isUpdatingSiteInProgress: false,
  isDeletionInProgress: false,
  isLoadingSpecificPageValuesInProgress: false,
  isUpdSpecificPageValuesInProgress: false,
  sites: [] as ISite[],
  site: null as ISite | null,
  sitesTotal: 0,
  sortByField: "createdAt" as SortByField,
  sortBy: "desc" as "asc" | "desc",
  serchByName: "",
  serchByState: "",
  serchByCity: "",
  page: 1,
  specificPageValues: [] as ISpecificPageValue[],
  inProgressId: null as null | string,
};

type InitialStateType = typeof initialState;

export const siteReducer = (
  state = initialState,
  action: SiteActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_IS_GETTING_SITES_IN_PROGRESS:
    case SET_IS_CREATING_SITE_IN_PROGRESS:
    case SET_IS_UPDATING_SITE_IN_PROGRESS:
    case SET_IS_DELETION_IN_PROGRESS:
    case SET_IS_LOADING_SPECIFIC_PAGE_VALUES_IN_PROGRESS:
    case SET_SITES:
    case SET_SORT_BY_FIELD:
    case SET_SORT_BY:
    case SET_PAGE:
    case SET_SITES_TOTAL:
    case SET_SEARCH_BY_NAME:
    case SET_SEARCH_BY_STATE:
    case SET_SEARCH_BY_CITY:
    case SET_SITE:
    case SET_SPECIFIC_PAGE_VALUES:
    case SET_IS_UPDATING_SPECIFIC_PAGE_VALUES_IN_PROGRESS:
    case SET_IN_PROGRESS_SITE_ID:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
