import React, { Dispatch, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { SiteThunkType } from "../store/actions/siteActions";
import { AnyAction } from "redux";
import {
  getCategories,
  setActiveAppliance,
} from "../store/actions/categoriesActions";
import { AppStateType } from "../store";
import { Button, message, Typography, Upload, UploadProps } from "antd";
import {
  getAppliances,
  getApplianceBrands,
  setBrands,
} from "../store/actions/applianceActions";
import { SearchBrandInput } from "../components/categories/SearchBrandInput";
import { UploadOutlined } from "@ant-design/icons";
import { mainUrl } from "../helpers/constants";

export const Categories = () => {
  const [activeCategory, setActiveCategory] = useState<null | string>(null);
  const [uploadProps, setUploadProps] = useState<UploadProps | null>(null);

  const { Link, Text } = Typography;
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;

  const categories = useSelector(
    (state: AppStateType) => state.categories.categories
  );

  const activeAppliance = useSelector(
    (state: AppStateType) => state.categories.activeAppliance
  );

  const appliances = useSelector(
    (state: AppStateType) => state.appliance.appliances
  );

  const applianceBrands = useSelector(
    (state: AppStateType) => state.appliance.applianceBrands
  );

  const isGettingBrandsInProgress = useSelector(
    (state: AppStateType) => state.appliance.isGettingBrandsInProgress
  );

  const isGettingAppliancesInProgress = useSelector(
    (state: AppStateType) => state.appliance.isGettingAppliancesInProgress
  );

  const isGettingCategoriesInProgress = useSelector(
    (state: AppStateType) => state.categories.isGettingCategoriesInProgress
  );

  useEffect(() => {
    let props: UploadProps | null = null;
    if (activeAppliance) {
      props = {
        name: "file",
        action: `${mainUrl}api/v1/category/image/upload/${activeAppliance}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        beforeUpload: (file) => {
          const isImage =
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/png";
          if (!isImage) {
            message.error(`${file.name} is not an image (jpg, png)`);
          }
          return isImage || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
          console.log("info.file", info.file);
          console.log(info.fileList);
          if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            if (activeCategory) dispatch(getAppliances(activeCategory));
          } else if (info.file.status === "error") {
            if (info.file?.response?.message) {
              message.error(info.file?.response?.message);
            } else {
              message.error(`${info.file.name} file upload failed.`);
            }
          }
        },
      };
    }
    setUploadProps(props);
  }, [activeAppliance, activeCategory]);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (categories.length > 0 && !activeCategory) {
      setActiveCategory(categories[0]._id);
    }
  }, [categories, activeCategory]);

  useEffect(() => {
    if (activeCategory) {
      dispatch(setBrands([]));
      dispatch(setActiveAppliance(null));
      dispatch(getAppliances(activeCategory));
    }
  }, [activeCategory]);

  useEffect(() => {
    if (activeAppliance) {
      dispatch(getApplianceBrands(activeAppliance));
    }
  }, [activeAppliance]);

  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        paddingTop: 20,
        flexDirection: "column",
      }}
    >
      {isGettingCategoriesInProgress && (
        <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
      )}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: 200, display: "flex", flexDirection: "column" }}>
          {categories.map((c) => (
            <Link
              style={{
                fontWeight: activeCategory === c._id ? "bold" : undefined,
                color: activeCategory === c._id ? "red" : undefined,
              }}
              href=""
              onClick={(e) => {
                e.preventDefault();
                setActiveCategory(c._id);
              }}
            >
              {c.name}
            </Link>
          ))}
        </div>
        <div style={{ width: 200, display: "flex", flexDirection: "column" }}>
          {isGettingAppliancesInProgress && (
            <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
          )}
          {appliances.map((c) => (
            <div style={{ flexDirection: "row" }}>
              <Link
                style={{
                  fontWeight: activeAppliance === c._id ? "bold" : undefined,
                  color: activeAppliance === c._id ? "red" : undefined,
                }}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setActiveAppliance(c._id));
                }}
              >
                {c.name}
              </Link>
            </div>
          ))}
        </div>
        <div style={{ width: 200, display: "flex", flexDirection: "column" }}>
          {isGettingBrandsInProgress && (
            <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
          )}
          {activeAppliance && (
            <>
              {uploadProps && (
                <div style={{ marginBottom: 10 }}>
                  {appliances.find((a) => a._id === activeAppliance)?.image && (
                    <div>
                      <img
                        style={{ width: 100 }}
                        src={appliances
                          .find((a) => a._id === activeAppliance)
                          ?.image?.replace("/data/uploads/", mainUrl)}
                      />
                    </div>
                  )}
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>
                      Upload Category Image
                    </Button>
                  </Upload>
                </div>
              )}

              <SearchBrandInput />
            </>
          )}
          {applianceBrands.map((c) => (
            <Text>{c.brand.name}</Text>
          ))}
        </div>
      </div>
    </div>
  );
};
